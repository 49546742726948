import { defineStore } from 'pinia'
import type { Card } from '~/ts/types/card'
import type { Plan } from '~/ts/types/plan'
import type { Invoice } from '~/ts/types/invoice'
import type { AppTablePagination } from '~/ts/types/app'
import { useUserStore } from '~/stores/user'

type PurchaseMethod = 'card' | 'direct'

export const useBillingStore = defineStore('billingStore', () => {
    const userStore = useUserStore()
    const notify = useNotify()
    const { tc } = useLang()

    const plans = ref<Plan[]>([])
    const cards = ref<Card[]>([])
    const invoices = ref<Invoice[]>([])
    const invoicePagination = ref<AppTablePagination | undefined>()
    const invoicePaginationPending = ref<boolean>(false)
    const purchaseMethod = ref<PurchaseMethod>('card')
    const firstPlanPrice = ref<number>(0)
    const selectedPlan = ref<Plan>()
    const selectedOperatorQuantity = ref<number>(userStore.activeSubscription?.operator_quantity || 1)
    const selectedCardId = ref<number>()
    const activePlan = shallowRef<Plan | undefined>()

    const planDurationLabelsByValue = computed<Record<number, string>>(() => ({
        1: tc('month-pluralized', 1),
        3: tc('month-pluralized', 3),
        12: tc('year-pluralized', 1),
        24: tc('year-pluralized', 2),
    }))

    const fillState = async (): Promise<void> => {
        if (plans.value.length) {
            return
        }

        const [ planData, cardData, invoiceData ] = await Promise.all([
            useApi().plan.all(),
            useApi().card.all(),
            useApi().invoice.all(),
        ])

        firstPlanPrice.value = parseFloat(planData.items[0].price)

        plans.value = planData.items.map((item) => {
            item.pricePerOperator = firstPlanPrice.value

            return {
                ...item,
                label: planDurationLabelsByValue.value[item.duration],
            }
        })
        cards.value = cardData.items
        invoices.value = invoiceData.items

        invoicePagination.value = invoiceData._meta

        selectedCardId.value = cards.value[0]?.id

        if (userStore.activeSubscription) {
            selectedPlan.value = activePlan.value = plans.value.find(v => v.id === userStore.activeSubscription.plan_id)
        } else {
            selectedPlan.value = plans.value[0]
        }
    }

    const paginateInvoices = async (page: number): Promise<void> => {
        invoicePaginationPending.value = true

        const data = await useApi().invoice.all({ page })

        invoices.value = data.items

        invoicePagination.value = data._meta

        invoicePaginationPending.value = false
    }

    const purchase = async (invoice?: Invoice): Promise<void> => {
        const currentPurchaseMethod: PurchaseMethod = invoice ? 'direct' : purchaseMethod.value

        if (!invoice) {
            invoice = await useApi().plan.purchase({
                id: selectedPlan.value.id,
                operatorQuantity: selectedOperatorQuantity.value,
            })
        }

        if (currentPurchaseMethod === 'card') {
            try {
                const data = await useApi().invoice.payWithCard({
                    id: invoice.id,
                    cardId: selectedCardId.value,
                })

                await paginateInvoices(invoicePagination.value.currentPage)

                navigateTo({
                    name: 'p-pid-billing-invoice-id',
                    params: { id: data.id },
                })
            } catch (error) {
                if (error.statusCode === 422) {
                    notify.push({
                        type: 'error',
                        text: error.data?.map(v => v.message).join('; '),
                    })
                } else {
                    throw error
                }
            }
        } else if (currentPurchaseMethod === 'direct') {
            const data = await useApi().invoice.payWithWayForPay({
                id: invoice.id,
            })

            location.href = data.url
        }
    }

    return {
        plans,
        cards,
        invoices,
        invoicePagination,
        invoicePaginationPending,
        purchaseMethod,
        firstPlanPrice,
        selectedPlan,
        selectedOperatorQuantity,
        selectedCardId,
        activePlan,
        planDurationLabelsByValue,
        fillState,
        paginateInvoices,
        purchase,
    }
})
